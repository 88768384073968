import './App.css';
import {Box} from "@chakra-ui/react";
import {HomePage} from "./pages/home.page";
import Navbar from "./components/navbar/nav.component";
import React from "react";
import {FooterComponent} from "./components/footer/footer.component";

function App() {
  return (
      <Box>
          <Navbar />
          <HomePage />
          <FooterComponent/>
      </Box>
  );
}

export default App;

