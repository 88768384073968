import React from 'react';
import PlaceholderTitle from '../assets/placeholder_title.jpeg';
import {Box, Center, Divider, Image} from "@chakra-ui/react";
import {Carousel} from "../components/carousel";
import {EmbeddedVideo} from "../components/embedded-video/embedded-video.component";

const PlaceholderWebsite = () => {
    return (
        <Box>
            <Center>
                <Image src={PlaceholderTitle} w="150px"/>
            </Center>
            <Divider mb={"30px"}/>
            <Carousel/>
            <Divider mb={"30px"}/>
            {/*<EmbeddedVideo />*/}
        </Box>
    );
}

export default PlaceholderWebsite;