import React, {useEffect, useState} from "react";
import {capsFirst} from "./utils";

import {Box, Button, Container, Divider, Flex, Heading, HStack, Image, Link, Tag, Text, VStack} from "@chakra-ui/react";

import ChakraCarousel from "./ChakraCarousel";
import Image1 from "../../assets/slideshow/1.png";
import Image2 from "../../assets/slideshow/2.png";
import Image3 from "../../assets/slideshow/3.png";

export const Carousel = () => {
    const data = [
        {
            title: 'One Piece Tournament Prize Set',
            img: Image1,
            link: 'https://www.instagram.com/p/CxImBR5PpY5/',
            description: `
            One Piece tournament prize set graded Perfect 10 by @cgccards (each of these cards is Pop 1 by CGC as a Perfect 10).
            `
        },
        {
            title: 'Comic Books',
            description: 'Major Comic Books available on Pro Retro X!\n',
            img: Image2,
            link: 'https://www.instagram.com/p/CxIb7i5PBCj/'
        },
        {
            title: 'Sports Cards',
            description: 'Legendary sports cards available on Pro Retro X!',
            img: Image3,
            link: 'https://www.instagram.com/p/CyEcOt8yGtp/'
        },
    ];

  return (
      <Container
        py={8}
        px={0}
        maxW={{
          base: "100%",
          sm: "35rem",
          md: "43.75rem",
          lg: "57.5rem",
          xl: "75rem",
          xxl: "87.5rem"
        }}
      >
        <ChakraCarousel gap={32}>
          {data.map((post, index) => (
            <Flex
              key={index}
              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
              justifyContent="space-between"
              flexDirection="column"
              overflow="hidden"
              color="gray.300"
              bg="white"
              rounded={5}
              flex={1}
              p={5}
            >
              <VStack mb={6} >
                <Heading
                  fontSize={{ base: "xl", md: "2xl" }}
                  height={'70px'}
                  textAlign="left"
                  color="black"
                  w="full"
                  mb={2}
                >
                  {capsFirst(post.title)}
                </Heading>
                  <Image
                      src={post.img}
                      borderRadius='lg'
                  />
                  <Box borderTop='1px dotted' borderColor='brand.200' paddingTop={'10px'}>
                      <Text w="full" color="black" >{capsFirst(post.description)}</Text>
                  </Box>

              </VStack>

              <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                  {/*    {post.price &&<Tag size="sm" variant="outline" colorScheme="green">*/}
                  {/*  Price: {post.price}*/}
                  {/*</Tag>}*/}
                </HStack>
                <Button
                  bg="brand.400"
                  fontWeight="bold"
                  color="gray.900"
                  size="sm"
                >
                    <Link href={post.link} variant='ghost' colorScheme='blue'>
                        More
                    </Link>
                </Button>
              </Flex>
            </Flex>
          ))}
        </ChakraCarousel>
      </Container>
  );
}
