import React from 'react';
import { Box, Link, Flex, Text } from '@chakra-ui/react';

const Navbar = () => {
    return (
        <Box bg="brand.400" py={4}>
            <Flex justify="space-between" align="center" px={6} maxW="1200px" mx="auto">
                <Text fontWeight="bold" color="black">
                    ProRetroX
                </Text>
                <Flex>
                    <Link href="/" textDecoration="none" mx={4}>
                        <Text fontWeight="bold" color="black">
                            Home
                        </Text>
                    </Link>
                    <Link href="#contact" textDecoration="none">
                        <Text fontWeight="bold" color="black">
                            Contact
                        </Text>
                    </Link>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Navbar;
