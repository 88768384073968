import {Box, Image, Link, Text} from "@chakra-ui/react";
import InstagramIcon from "../../assets/instagram_icon.svg";
import React from "react";
import './footer.component.css';

export const FooterComponent = () => {
    return (
        <section id={'contact'}>
            <Box display={'flex'} alignItems={'center'} bg={'brand.400'} flexDirection={'column'} gap={'10px'}
                 p={'10px'}>
                <Text align={'center'}>
                    We're working hard to bring you an amazing website. Stay tuned for updates!
                </Text>
                <Box>
                    <Link href="https://www.instagram.com/proretrox/" target="_blank" rel="noopener noreferrer">
                        <Image src={InstagramIcon} w={'24px'}/>
                    </Link>
                </Box>
                <Text fontSize='md' textAlign={'center'}>For inquiries, contact us at <Link
                    href="mailto:nido@proretrox.com">nido@proretrox.com</Link></Text>
            </Box>
        </section>
    )
}