import {extendTheme} from "@chakra-ui/react";

export const theme = extendTheme({
    colors: {
        brand: {
            100: "#78c5bf",
            200: "#4398b7",
            300: "#f1feff",
            400: "#ebda12",
        },
    },
    styles: {
        global: {
            body: {
                bg: 'brand.200',

            },
        },
    },
})